<script setup>
import MainLayout from "../components/MainLayout.vue";

// Page Title of Views
const pageTitle = "Projects";
</script>

<template>
  <MainLayout :pageTitle="pageTitle">
    <section>
      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
          <!-- text - start -->
          <div class="mb-10 md:mb-16">
            <h2
              class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-4 lg:text-3xl"
            >
              Latest Project
            </h2>
          </div>
          <!-- text - end -->

          <div class="grid gap-6 sm:grid-cols-2">
            <!-- product - start -->
            <router-link to="/ToDo"
              href="#"
              class="group relative flex h-80 items-end overflow-hidden rounded-lg bg-gray-100 p-4 shadow-lg"
            >
              <img
                src="../assets/todo.png"
                loading="lazy"
                alt="Photo by Fakurian Design"
                class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
              />

              <div
                class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"
              ></div>

              <div class="relative flex flex-col">
                <span class="text-gray-300">To do</span>
                <span class="text-lg font-semibold text-white lg:text-xl"
                  >list your to do</span
                >
              </div>
            </router-link>
            <!-- product - end -->

            <!-- product - start -->
            <router-link to="/post"
              class="group relative flex h-80 items-end overflow-hidden rounded-lg bg-gray-100 p-4 shadow-lg"
            >
              <img
                src="../assets/vue-portfolio-template.png"
                loading="lazy"
                alt="Photo by Fakurian Design"
                class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
              />

              <div
                class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"
              ></div>

              <div class="relative flex flex-col">
                <span class="text-gray-300">Announcement App</span>
                <span class="text-lg font-semibold text-white lg:text-xl">Create your announcement</span>
                >
              </div>
            </router-link>
            <!-- product - end -->
          </div>
        </div>
      </div>
    </section>
  </MainLayout>
</template>
