<template>
  <!-- Todo App -->
  <div class="main bg-white min-h-screen flexitems-center justify-center max-w-screen-lg mx-auto">
    <div class="container bg-white p-8 rounded-lg shadow-md">
      <div class="title mb-8">
        <h1 class="text-cyan-700 text-4xl font-semibold">Todo App</h1>
      </div>
      <div class="actions mb-4">
        <form @submit.prevent="addTask" class="flex items-center">
          <input
            type="text"
            placeholder="Add Task"
            v-model="task"
            required
            class="p-2 border border-gray-300 rounded-l"
          />
          <button
            class="px-4 py-2 bg-cyan-700 text-white rounded-r"
            type="submit"
          >
            Add
          </button>
          <input
            type="text"
            placeholder="Search task"
            v-model="searchQuery"
            class="ml-3 p-2 border border-gray-300 rounded"
          />
        </form>
      </div>
      <div class="tasks">
        <div
          class="task-items flex items-center justify-between border-b border-gray-300 py-2"
          v-for="todo in filteredTodos"
          :key="todo.id"
        >
          <p :class="{ 'line-through': todo.done }" class="mx-4">
            {{ todo.details }}
          </p>
          <button
            class="done-btn ml-4 px-3 py-1 bg-green-500 text-white rounded"
            @click="markAsDone(todo.id)"
          >
            Done
          </button>
          <button
            class="remove-btn ml-4 px-3 py-1 bg-red-500 text-white rounded"
            @click="removeTask(todo.id)"
          >
            Remove
          </button>
        </div>
        <button
          class="clear-btn mt-4 px-4 py-2 bg-red-500 text-white rounded"
          @click="clearAllTasks"
        >
          Clear ALL tasks
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import database from '../firebase';
import { ref, computed, onMounted } from 'vue';
import { collection, addDoc, getDocs, deleteDoc, updateDoc, doc } from 'firebase/firestore';

const db = database;
const task = ref('');
const searchQuery = ref('');
const todos = ref([]);

// Function to fetch tasks from Firestore
const fetchTasks = async () => {
try {
  const querySnapshot = await getDocs(collection(db, 'tasks'));
  todos.value = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
} catch (error) {
  console.error('Error fetching tasks:', error);
}
};

// Fetch tasks on component mount
onMounted(fetchTasks);

const addTask = async () => {
try {
  if (task.value.trim() !== '') {
      const newTask = { details: task.value, done: false };
      const docRef = await addDoc(collection(db, 'tasks'), newTask);
      todos.value.push({ ...newTask, id: docRef.id });
      task.value = '';
  }
} catch (error) {
  console.error('Error adding task:', error);
}
};

const removeTask = async (taskId) => {
try {
  await deleteDoc(doc(db, 'tasks', taskId));
  todos.value = todos.value.filter((todo) => todo.id !== taskId);
} catch (error) {
  console.error('Error removing task:', error);
}
};

const markAsDone = async (taskId) => {
try {
  const todo = todos.value.find((t) => t.id === taskId);
  if (todo) {
      await updateDoc(doc(db, 'tasks', taskId), { done: !todo.done });
      todo.done = !todo.done;
  }
} catch (error) {
  console.error('Error updating task:', error);
}
};

const clearAllTasks = async () => {
try {
  const querySnapshot = await getDocs(collection(db, 'tasks'));
  querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
  });
  todos.value = [];
} catch (error) {
  console.error('Error clearing all tasks:', error);
}
};

const filteredTodos = computed(() => {
return todos.value.filter((todo) =>
  todo.details.toLowerCase().includes(searchQuery.value.toLowerCase())
);
});
</script>