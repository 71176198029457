<script setup>
import MainLayout from "../components/MainLayout.vue";

// Page Title of Views
const pageTitle = "About";
</script>

<template>
  <MainLayout :pageTitle="pageTitle">
    <section>
      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <h1 class="text-5xl font-bold text-gray-400">About me</h1>
        <div class="flex justify-between">
          <div class="mt-12">
            <h1 class="text-gray-500">Code poet sculpting digital dreams, weaving innovation with pixels. <br> Embracing challenges, I craft the future, one line at a time.</h1>
          <div class="hidden lg:block mt-3">
                    <ul class="flex flex-row items-center gap-3">
                        <a href="https://www.facebook.com/nelgodinar" target="_blank" class="bg-gray-100 hover:bg-gray-200 p-2 rounded-full">
                            <svg class="w-[20px] h-[20px] text-gray-600" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                <path fill-rule="evenodd"
                                    d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                                    clip-rule="evenodd" />
                            </svg>
                        </a>
                        <a href="https://github.com/migos143" target="_blank" class="bg-gray-100 hover:bg-gray-200 p-2 rounded-full">
                            <svg class="w-[24px] h-[24px] text-gray-600" width="24" height="24" viewBox="0 0 24 24"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                            </svg>
                        </a>
                    </ul>
                </div>
          </div>
          <div>
            <h1 class="font-bold text-3xl text-center mb-3">Code Symphony: Crafting  <br> Tomorrow's Possibilities with <br> Creativity, Innovation, <br> and Logic</h1>
            <h1 class="text-lg font-semibold text-gray-400">In the symphony of algorithms, a developer orchestrates creativity, <br> innovation, and logic. Crafting the digital narrative, we architect the future <br> with lines of code, shaping tomorrow's possibilities.</h1>
          </div>
        </div>
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8 mt-12">
          <h2
            class="mb-8 text-center text-2xl font-bold text-gray-800 md:mb-12 lg:text-5xl"
          >
            Skills
          </h2>

          <div
            class="grid gap-4 sm:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-4"
          >
            <!-- product - start -->
            <div>
              <a
                href="#"
                class="group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 p-4 shadow-lg"
              >
                <img
                  src="https://res.cloudinary.com/thewebmaster/image/upload/c_scale,f_auto,q_auto:best,w_516/images/html/html5.png"
                  loading="lazy"
                  alt="Photo by Austin Wade"
                  class="absolute inset-0 h-full w-full object-contain object-center transition duration-200 group-hover:scale-110"
                />

                <div
                  class="relative flex w-full flex-col rounded-lg bg-white p-4 text-center"
                >
                  <span class="text-lg font-bold text-gray-800 lg:text-xl"
                    >Html</span
                  >
                </div>
              </a>
            </div>
            <!-- product - end -->

            <!-- product - start -->
            <div>
              <a
                href="#"
                class="group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 p-4 shadow-lg"
              >
                <img
                  src="https://cdn.sanity.io/images/599r6htc/localized/46a76c802176eb17b04e12108de7e7e0f3736dc6-1024x1024.png?w=670&h=670&q=75&fit=max&auto=format"
                  loading="lazy"
                  alt="Photo by engin akyurt"
                  class="absolute inset-0 h-full w-full object-contain object-center transition duration-200 group-hover:scale-110"
                />

                <div
                  class="relative flex w-full flex-col rounded-lg bg-white p-4 text-center"
                >
                  <span class="text-lg font-bold text-gray-800 lg:text-xl"
                    >Figma</span
                  >
                </div>
              </a>
            </div>
            <!-- product - end -->

            <!-- product - start -->
            <div>
              <a
                href="#"
                class="group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 p-4 shadow-lg"
              >
                <img
                  src="https://seeklogo.com/images/B/bootstrap-logo-3C30FB2A16-seeklogo.com.png"
                  loading="lazy"
                  alt="Photo by Austin Wade"
                  class="absolute inset-0 h-96 w-80 object-contain object-center transition duration-200 group-hover:scale-110"
                />

                <div
                  class="relative flex w-full flex-col rounded-lg bg-white p-4 text-center"
                >
                  <span class="text-lg font-bold text-gray-800 lg:text-xl"
                    >Bootstrap</span
                  >
                </div>
              </a>
            </div>
            <!-- product - end -->

            <!-- product - start -->
            <div>
              <a
                href="#"
                class="group relative flex h-96 items-end overflow-hidden rounded-lg bg-gray-100 p-4 shadow-lg"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/600px-JavaScript-logo.png?20120221235433"
                  loading="lazy"
                  alt="Photo by Austin Wade"
                  class="absolute inset-0 h-full w-full object-contain object-center transition duration-200 group-hover:scale-110"
                />

                <div
                  class="relative flex w-full flex-col rounded-lg bg-white p-4 text-center"
                >
                  <span class="text-lg font-bold text-gray-800 lg:text-xl"
                    >Javascript</span
                  >
                </div>
              </a>
            </div>
            <!-- product - end -->
          </div>
        </div>
      </div>
    </section>
  </MainLayout>
</template>
