<script setup>
import MainLayout from "../components/MainLayout.vue";

// Page Title of Views
const pageTitle = "Contact";
</script>

<template>
  <MainLayout :pageTitle="pageTitle">
    <section>
      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
          <!-- quote - start -->
          <div class="flex flex-col items-center gap-4 md:gap-6">
            <h1
              href="#"
              class="text-gray-400 text-8xl cursor-pointer transition duration-100 hover:text-gray-500 active:text-gray-600"
            >
              Connect with me
            </h1>

            <div class="max-w-md text-center text-gray-600 lg:text-lg">
              “Feel free to reach out for collaboration, inquiries, or any questions. I welcome your messages warmly.”
            </div>

            <div class="flex flex-col items-center gap-2 sm:flex-row md:gap-3">
              <div
                class="h-12 w-12 overflow-hidden rounded-full bg-gray-100 shadow-lg md:h-14 md:w-14"
              >
                <img
                  src="https://scontent.fceb3-1.fna.fbcdn.net/v/t39.30808-6/305850426_1497590927423520_7693153736669025695_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=9c7eae&_nc_eui2=AeEQJjygDp1ocSR9ISa5UEuQzB0FMTkwEfDMHQUxOTAR8EY-sLGprzpHQaQK9RxNtMHxIEU9ZDLYuYIdohB1gnhf&_nc_ohc=a4QqtKlXCuAAX8OydW0&_nc_ht=scontent.fceb3-1.fna&oh=00_AfCyCL2isCoi3TDVLr6_2HhImXE_Ixdfud9HuQ8NMQC9Vw&oe=65867802"
                  loading="lazy"
                  alt="Photo by Radu Florin"
                  class="h-full w-full object-cover object-center"
                />
              </div>

              <div>
                <div
                  class="text-center text-sm font-bold text-indigo-500 sm:text-left md:text-base"
                >
                  Glen Ranido
                </div>
                <p href="glenranido11@gmail.com"
                  class="text-center text-sm text-gray-500 sm:text-left md:text-sm"
                >
                  glenranido11@gmail.com
              </p>
              </div>
            </div>
          </div>
          <!-- quote - end -->
        </div>
      </div>
      <div class="hidden lg:flex justify-center">
                    <ul class="flex flex-row items-center gap-3">
                        <a href="https://www.facebook.com/nelgodinar" target="_blank" class="bg-gray-100 hover:bg-gray-200 p-2 rounded-full">
                            <svg class="w-[20px] h-[20px] text-gray-600" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                <path fill-rule="evenodd"
                                    d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                                    clip-rule="evenodd" />
                            </svg>
                        </a>
                        <a href="https://github.com/migos143" target="_blank" class="bg-gray-100 hover:bg-gray-200 p-2 rounded-full">
                            <svg class="w-[24px] h-[24px] text-gray-600" width="24" height="24" viewBox="0 0 24 24"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                            </svg>
                        </a>
                    </ul>
                </div>
    </section>
  </MainLayout>
</template>
