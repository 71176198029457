<script setup>
import database from '../firebase';
import { ref, computed, onMounted } from 'vue';
import { collection, query, onSnapshot, serverTimestamp, getDoc, addDoc, deleteDoc, updateDoc, doc } from "firebase/firestore";

const db = database;
const data = ref([]);
const FormData = ref({
    title: null,
    description: null,
});

// Get the Data Real Time
const renderData = () => {
  const queryData = query(collection(db, "announce"));

  onSnapshot(queryData, (querySnapshot) => {
    const announcements = [];
    querySnapshot.forEach((item) => {
      const announcement = {
        id: item.id,
        title: item.data().title,
        description: item.data().description,
      }
      announcements.push(announcement);
    });
    data.value = announcements;
  });
};

const addAnnouncement = async () => {

    const response = await addDoc(collection(db, "announce"), {
        title: FormData.value.title,
        description: FormData.value.description,
        date_enrolled: serverTimestamp()
    });

    console.log(response.id);

    if (response.id) {
        FormData.value.title = null;
        FormData.value.description = null;
    }
};

onMounted(() => {
    renderData();
});

</script>

<template>
    <div>
        <!-- Create Post -->
        <div>
            <div class="flex flex-col items-center pt-6 bg-gray-100 sm:justify-center sm:pt-0">
                <div class="w-full px-16 py-20 mt-6 overflow-hidden bg-white rounded-lg lg:max-w-4xl">
                    <div class="mb-4">
                        <h1 class="font-serif text-3xl font-bold underline decoration-gray-400">
                            Create Announcement
                        </h1>
                    </div>

                    <!-- Form -->
                    <div class="w-full px-6 py-4 bg-white rounded shadow-md ring-1 ring-gray-900/10">
                        <div>
                            <!-- Title -->
                            <div>
                                <label class="block text-sm font-bold text-gray-700" for="title">
                                    Title
                                </label>

                                <input v-model="FormData.title"
                                    class="block w-full mt-1 border-gray-300 rounded-md shadow-sm placeholder:text-gray-400 placeholder:text-right focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    type="text" name="email" placeholder="180" />
                            </div>

                            <!-- Description -->
                            <div class="mt-4">
                                <label class="block text-sm font-bold text-gray-700" for="password">
                                    Description
                                </label>
                                <textarea name="description" v-model="FormData.description"
                                    class="block w-full mt-1 border-gray-300 rounded-md shadow-sm placeholder:text-gray-400 placeholder:text-right focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    rows="4" placeholder="400"></textarea>
                            </div>

                            <div class="flex items-center justify-start mt-4 gap-x-2">
                                <button @click="addAnnouncement"
                                    class="px-6 py-2 text-sm font-semibold rounded-md shadow-md text-sky-100 bg-sky-500 hover:bg-sky-700 focus:outline-none focus:border-gray-900 focus:ring ring-gray-300">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Post VIew -->
        <div class="flex flex-col items-center pt-6 bg-gray-100 sm:justify-center sm:pt-0">
            <div class="w-full px-16 py-20 mt-6 overflow-hidden bg-white rounded-lg lg:max-w-4xl">
                <div class="mb-4">
                    <h1 class="font-serif text-3xl font-bold underline decoration-gray-400">
                        Announcements
                    </h1>
                </div>

                <div v-for="announce in data"  class="w-full px-6 mb-6 py-4 bg-white rounded shadow-md ring-1 ring-gray-900/10">
                    <form method="POST" action="#">
                        <!-- Title -->
                        <div>
                            <h3 class="text-2xl font-semibold">
                                {{ announce.title }}
                            </h3>
                            <p class="text-base text-gray-700">
                                {{ announce.description }}
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
